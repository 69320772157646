import React from "react"
import { motion } from "framer-motion"
import { pageFade, pageTransistion } from "../../helpers/FramerMotionAnim"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ContactHero = () => {
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageFade}
      transition={pageTransistion}
    >
      <section className="contact--hero">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 contact--hero__info">
              <h2 className="pb-4">Contact Us</h2>
              <h5>Email</h5>
              <a href="mailto:hello@cafekraken.com">
                <p>hello@cafekraken.com</p>
              </a>

              <h5 className="pt-4">Address</h5>
              <p>135 Hollow Way, Oxford OX4 2NE</p>

              <h5 className="pt-4">Telephone</h5>
              <a href="tel:07568 237193">
                <p>07568 237193</p>
              </a>

              <div className="contact--hero__info--socials pt-4">
                <a
                  href="https://www.facebook.com/cafekrakenoxford/"
                  target="_blank"
                  className="px-1"
                >
                  <FontAwesomeIcon icon={["fab", "facebook"]} />
                </a>
                <a
                  href="https://www.instagram.com/cafekrakenuk/"
                  target="_blank"
                  className="px-1"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} />
                </a>
                <a
                  href="https://twitter.com/cafekraken?lang=en"
                  target="_blank"
                  className="px-1"
                >
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </a>
                <a
                  href="https://www.linkedin.com/company/cafe-kraken/"
                  target="_blank"
                  className="px-1"
                >
                  <FontAwesomeIcon icon={["fab", "linkedin"]} />
                </a>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2470.723277044704!2d-1.209540348478242!3d51.738096579573934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876c1ec7e8fb667%3A0x1bfe7c3bf6549c1c!2sCafe%20Kraken!5e0!3m2!1sen!2sie!4v1631180242363!5m2!1sen!2sie"
                width={"100%"}
                height={600}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                className="contact--hero--map"
              />
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  )
}

export default ContactHero
